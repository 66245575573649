import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable,from } from 'rxjs';
import { switchMap,catchError } from 'rxjs/operators';
import { APIErrorHandler } from './api-error-handler.service';
import { AppConfigService, AppConfig } from '@app/app-config.service';
import { MsalService  } from '@azure/msal-angular';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private _config: AppConfig;

  constructor(
    private _appConfig: AppConfigService,
    public errorHandler: APIErrorHandler,
    private msalService:MsalService
  ) {
    this._config = this._appConfig.getConfig();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = request.url;
	const apiScope = this._config.msalConfig.geobrain_scope;
	if (requestUrl.includes(this._config.datascience_api_baseurl) || requestUrl.includes(this._config.geoBertAPIUrl)) {
		return from(this.msalService.acquireTokenSilent({
		   scopes:[apiScope]
		})).pipe(switchMap((token:any)=>{
			if (!this._config.datascience_api_token) {
			  request = request.clone({
				headers: new HttpHeaders({
				  'Cache-Control': 'no-cache',
				  'Pragma': 'no-cache'
				}),
				withCredentials: true
			  });
			} else {
			  request = request.clone({
				headers: new HttpHeaders({
				  'Cache-Control': 'no-cache',
				  'Pragma': 'no-cache'
				}),
				setHeaders: {
				  'Authorization' : 'Bearer '+token.accessToken
				}
			  });
			}
			return next.handle(request);
		}));
	}else{
			return next.handle(request);
	}
	 
  }
}
